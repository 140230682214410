import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { graphql } from 'gatsby';

import { getImagePreview } from 'utils';

import { Paragraphs, Layout, SEO } from 'components';

const Index = ({
  location: { pathname: siteUrl },
  data: {
    nodePage: {
      title,
      field_metatags,
      field_automatic_breadcrumbs,
      field_alternative_design,
      relationships: {
        field_content,
        field_image_header,
        field_header_paragraph,
        field_breadcrumbs,
      },
    },
  },
}) => {
  const imageOpenGraphPreview = field_image_header?.localFile?.childImageSharp.fluid.src;
  const isAlternativeHeader = field_alternative_design;
  const imageUrl = getImagePreview(imageOpenGraphPreview);

  return (
    <Layout
      headerData={field_header_paragraph}
      nodeTitle={title}
      type="front"
      isDefaultBreadcrumbs={field_automatic_breadcrumbs}
      customBreadcrumbs={field_breadcrumbs}
      isAlternativeHeader={isAlternativeHeader}
    >
      <SEO
        title={field_metatags ? field_metatags.title : title}
        description={field_metatags ? field_metatags.description : ''}
        keywords={field_metatags ? field_metatags.keywords : ''}
        robots={field_metatags?.robots}
        currentUrl={siteUrl}
        imageUrl={imageUrl}
      />
      {field_content.map((item) => (
        <Paragraphs key={uid(item)} data={item} />
      ))}
      <div itemScope itemType="https://schema.org/Website" className="schema-snippet hidden">
        <span itemProp="name">AnyforSoft</span>
        <link itemProp="url" href="https://anyforsoft.com/" />
        <form itemProp="potentialAction" itemScope itemType="https://schema.org/SearchAction">
          <meta itemProp="target" content="https://anyforsoft.com/?q={search_term_string}" />
          <input itemProp="query-input" type="text" name="search_term_string" required />
        </form>
      </div>
      <script type="application/ld+json">
        {`
          {
            "@context": {
              "@vocab": "https://schema.org/"
            },
            "@graph": [
              {
                "@id": "https://anyforsoft.com/",
                "@type": "Organization",
                "name": "Anyforsoft",
                "url" : "https://anyforsoft.com/",
                "logo" : "https://anyforsoft.com/anyforsoft_social.jpg",
                "sameAs": [
                  "https://www.facebook.com/anyforsoft/",
                  "https://twitter.com/anyforsoft?lang=en",
                  "https://www.instagram.com/anyforsoft/?hl=en",
                  "https://www.linkedin.com/company/anyforsoft/",
                  "https://anyforsoft.com/"
                ]
              },
              {
                "@type": "LocalBusiness",
                "parentOrganization": {
                  "name" : "Anyforsoft"
                },
                "name" : "Anyforsoft USA",
                "image" : "https://anyforsoft.com/anyforsoft_social.jpg",
                "address": {
                  "@type" : "PostalAddress",
                  "streetAddress": "Villagio Circle",
                  "addressLocality": "Sarasota",
                  "addressRegion": "FL",
                  "postalCode": "79021",
                  "telephone" : "+1 (941) 932-8580"
                },
                "openingHours": "09:00-18:00"
              },
              {
                "@type": "LocalBusiness",
                "parentOrganization": {
                  "name" : "Anyforsoft"
                },
                "name" : "Anyforsoft Latvia",
                "image" : "https://anyforsoft.com/anyforsoft_social.jpg",
                "address": {
                  "@type" : "PostalAddress",
                  "streetAddress": "Mazā Briežu iela 3",
                  "addressLocality": "Mārupe",
                  "addressRegion": "Mārupes novads",
                  "postalCode": "LV-2167",
                  "telephone" : "+1 (941) 932-8580"
                },
                "openingHours": "09:00-18:00"
              }
            ]
          }
        `}
      </script>
    </Layout>
  );
};

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.string,
      field_metatags: PropTypes.object,
      field_automatic_breadcrumbs: PropTypes.bool,
      field_alternative_design: PropTypes.bool,
      relationships: PropTypes.shape({
        field_content: PropTypes.array,
        field_image_header: PropTypes.object,
        field_header_paragraph: PropTypes.array,
        field_breadcrumbs: PropTypes.array,
      }),
    }),
  }),
};

export default Index;

// GraphQL query for front page only.
export const query = graphql`
  {
    nodePage(drupal_internal__nid: { eq: 150 }) {
      ...NodeMainPage
    }
  }
`;
